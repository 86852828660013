@font-face {
  font-family: "Calma";
  src: url("../public/fonts/Calma-Medium/fonts/Calma-Medium.eot");
  src: url("../public/fonts/Calma-Medium/fonts/Calma-Medium.woff")
      format("woff"),
    url("../public/fonts/Calma-Medium/fonts/Calma-Medium.ttf") format("ttf"),
    url("../public/fonts/Calma-Medium/fonts/Calma-Medium.svg") format("svg");
}

@font-face {
  font-family: "Maison Neue";
  src: url("../public/fonts/MaisonNeueBook/fonts/MaisonNeueBook.eot");
  src: url("../public/fonts/MaisonNeueBook/fonts/MaisonNeueBook.woff")
      format("woff"),
    url("../public/fonts/MaisonNeueBook/fonts/MaisonNeueBook.ttf") format("ttf"),
    url("../public/fonts/MaisonNeueBook/fonts/MaisonNeueBook.svg") format("svg");
}

body > * {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Maison Neue";
  width: 100vw;
  overflow-x: hidden;
  font-size: calc(9px + 0.6vw);
  --black: #131313;
  --light-grey: #f2f2f2;
  --light-grey-2: #f7f7f7;
  --light-grey-3: #d8d8d8;
  --grey: #8c8c8c;
  --grey-2: #979797;
  height: 100vh;
  overflow: hidden;
  color: var(--black);
}

main {
  position: relative;
}

img {
  object-position: top right;
}

a:link {
  color: var(--black);
}

a:visited {
  color: var(--black);
}

:global(.slidein) {
  animation: slidein 1s ease-in-out;
  position: relative;
}

@keyframes slidein {
  from {
    opacity: 0;
    top: 1rem;
  }

  to {
    opacity: 1;
    top: 0;
  }
}
