.slider {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: row;
  grid-template-rows: auto 3em;
  grid-template-columns: 5em 1fr 5em;
  column-gap: 3em;
}

.next,
.prev {
  background: white;
  border-radius: 30px;
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  grid-row: 2;
}

.next {
  right: 5em;
  grid-column: 3;
}

.prev {
  grid-column: 1;
  left: 5em;
  transform: scale(-1);
}

.slide {
  position: relative;
  top: 0;
  max-width: 100vw;
  grid-row: 1;
  grid-column: 1/4;
}

.arrow {
  height: 4rem;
  width: 2rem;
  z-index: 999;
}

.arrow.slick-next,
.arrow.slick-prev {
  content: none;
}

.arrow::after,
.arrow::before {
  display: none;
}

.dots {
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 2;
  grid-column: 2;
}

.dot {
  margin: 0 1rem;
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
}

@media screen and (min-width: 940px) {
  .next,
  .prev {
    grid-row: 1;
  }

  .slide {
    grid-column: 2;
  }
}
