.footer {
  display: grid;
  padding: 2rem;
  padding-top: 7.5rem;
  /* grid-template-rows: 1fr 1fr; */
  grid-template-columns: 1fr 1fr 0.3fr;
}

.copyright {
  grid-row: 1;
  font-size: 9rem;
  line-height: 8rem;
  padding-bottom: 3rem;
  /* transform: translateX(-1.5%); */
}
.copyright > a {
  text-decoration: none;
  transform: translateX(-0.05em);
  display: flex;
}

.links {
  grid-row: 2;
  display: flex;
  flex-direction: column;
}

.links a {
  color: inherit;
  line-height: 2.4rem;
}

.madeby {
  margin-top: 0rem;
  grid-row: 3;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.madeby p {
  line-height: 2rem;
}

.madeby p a:link {
  color: var(--black);
}

.madeby p a:visited {
  color: var(--black);
}

.arrow {
  grid-row: 3;
  grid-column: 3;
  align-self: flex-end;
  justify-self: flex-end;
}

.arrow:hover {
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .madeby {
    margin-top: 0;
    grid-row: 2;
    grid-column: 2;
    display: flex;
    flex-direction: column;
  }

  .arrow {
    grid-row: 2;
    grid-column: 3;
    text-align: right;
  }
}

@media screen and (min-width: 1080px) {
  .footer {
    padding: 4.5rem;
    padding-top: 5rem;
    grid-template-columns: 2fr 1fr 0.3fr;
  }
}
