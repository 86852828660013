.galerie {
  display: grid;
  position: relative;
  align-items: start;
}

.galerie > * {
  box-shadow: inset 0px -1px 0px #d8d8d8;
}

.painting {
  height: 51rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.painting .title {
  margin: 0;
  margin-bottom: 2rem;
  line-height: 100%;
}

.painting .available {
  margin: 0;
  margin-top: 2rem;
}

.separator {
  width: 100%;
  height: 0.1rem;
  background: var(--light-grey);
}

.pictureContainer {
  position: relative;
  overflow: hidden;
}

.picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  text-align: center;
  font-size: 3.5rem;
  margin-bottom: 5rem;
  margin-top: 4rem;
  font-family: "Calma";
  font-weight: 200;
}

.dimension {
  margin-top: 2rem;
  padding-bottom: 0.2rem;
}

.technique {
  color: var(--grey);
}

.available {
  margin-top: 2rem;
  margin-bottom: 6rem;
  color: var(--grey);
}

.scrollable {
  display: flex;
  position: relative;
}

.scrollable > a {
  min-width: 80%;
}

.painting:hover .hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.65);
}

.hover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("./resources/hover.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 180px 180px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, background-color 0.5s;
}

.painting:hover img {
  transition: all 0.5s;
}

.painting:hover img {
  filter: blur(2px);
}

@media screen and (min-width: 600px) {
  .scrollable > a {
    min-width: 30rem;
  }
}

.infiniteScroller {
  animation-duration: 400s;
  animation-iteration-count: infinite;
  animation-name: scrollX;
}
@keyframes scrollX {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.infiniteScroller > * {
  transform: translateX(-100%);
}
